import React from "react"
import "./TextArea.scss"

const TextArea = ({ onChange, value, customClass }) => {
  return (
    <textarea
      onChange={onChange}
      className={`${customClass} ${"textArea__style"}`}
      value={value}
      rows="12"
      cols="90"
      required
    />
  )
}

export default TextArea
