import React, { useContext, useCallback } from "react"
import { myContext } from "../provider"
import { validateEmail } from "../utils"

const useSubscribeToEverlytic = (email, component, isClicked) => {
  const context = useContext(myContext)
  const {
    userSubscribed,
    subscribeComponent,
    userSubscribedError,
    subscribeToNewsLetter
  } = context

  const submitForm = useCallback(() => {
    const payload = {}

    if(isClicked) {
      if(validateEmail(email)) {
        payload.name = "Subscribed",
        payload.email = email,
        payload.mobile = "",
        payload.on_duplicate = "update",
        payload.list_id = {
          [process.env.GATSBY_EVERLYTIC_SUBSCRIPTION_LIST_ID]: "subscribed",
        }

        subscribeToNewsLetter(payload, component)
      }
    }
  }, [isClicked])

  return [
    userSubscribed,
    subscribeComponent,
    userSubscribedError,
    submitForm
  ]
}

export default useSubscribeToEverlytic
