import React from "react"
import Buttons from "react-bootstrap/Button"

const Button = ({
  title,
  type,
  customClass,
  size,
  disabled,
  onClick,
  style,
  color,
}) => {
  return (
    <Buttons
      className={customClass}
      type={type}
      size={size}
      style={{
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "13px",
        borderRadius: "5em",
        padding: ".7em 3em",
        border: `3px solid var(--mainMediumAquamarine)`,
        color:
          color === "white" ? `var(--mainWhite)` : `var(--mainBlackRussian)`,
        backgroundColor:
          color === "white" ? `var(--mainBlackRussian)` : `var(--mainWhite)`,
      }}
      disabled={disabled && disabled}
      onClick={onClick}
      variant="none"
    >
      {title}
    </Buttons>
  )
}

export default Button
