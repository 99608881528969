import SmartAdvanceImg from "../assets/images/our-portfolio/Fin Payroll Loans Dark.png"
import HomeLoansImg from "../assets/images/our-portfolio/Fin Home Loans.png"
import FikiaFinanceImg from "../assets/images/our-portfolio/Fin_Logo_Fin Kenya_Dark.png"
import eFin from "../assets/images/our-portfolio/Fin Pay Dark.png"
import MediFinImg from "../assets/images/our-portfolio/Fin Medical Dark.png"
import NiftyCoverImg from "../assets/images/our-portfolio/Fin Cover Dark.png"
import NiftyCreditImg from "../assets/images/our-portfolio/Fin Online Dark.png"
import FinTanzaniaImg from "../assets/images/our-portfolio/Fin_Logo_Fin_Tanzania.svg"

const allOurCoreProducts = [
  {
    id: 1,
    text: "Leveraging the use of technology developed in-house, Fin Payroll Loans delivers innovative and simple financial products, including personal loans and insurance, to enable and empower employers, employees and consumers. Fin Payroll Loans currently services leading African brands in the FMCG, medical services and retail sectors. It is a registered financial services provider.",
    title: "Fin Payroll Loans",
    facebookUrl: "https://www.facebook.com/finafricaza",
    linkedInUrl: "https://www.linkedin.com/company/hqfinafrica/",
    image: SmartAdvanceImg,
    website: "https://payroll.fin.africa",
  },
  // {
  //   id: 4,
  //   text: "NiftyPay provides inclusive financial services in Sub-Saharan Africa. Our mission is to provide simple, affordable and hassle-free financing. We provide customers with a one-click, Buy Now Pay Later, Subscription and Credit omni channel-platform.",
  //   title: "NiftyPay",
  //   facebookUrl: "https://www.facebook.com/NiftyPay-100211372112269",
  //   linkedInUrl: "https://www.instagram.com/click.2.pay/",
  //   image: NiftyPayImg,
  //   website: "https://www.niftypay.co.za/",
  // },
  {
    id: 2,
    text: "Fin Online Loans offers personal credit through an online portal, using smart technology, where customers can apply for credit online or through the Fin Online Loans app within minutes. Our system offers personalised credit based on positive payment behaviour, allowing our customers to get money when they need it on better repayment terms.",
    title: "Fin Online Loans",
    facebookUrl: "https://www.facebook.com/finafricaza",
    linkedInUrl: "https://www.linkedin.com/company/hqfinafrica/",
    image: NiftyCreditImg,
    website: "https://onlineloans.fin.africa",
  },
  {
    id: 3,
    text: "This authorised financial services provider offers insurance products and employee benefit solutions for individuals and employers, including funeral cover, commuter cover, credit life and group medical insurance. These services are supported by smart technology to make the application process easy and enable customers to maintain control of their insurance products online",
    title: "Fin Cover",
    facebookUrl: "https://www.facebook.com/finafricaza",
    linkedInUrl: "https://www.linkedin.com/company/hqfinafrica/",
    image: NiftyCoverImg,
    website: "https://cover.fin.africa",
  },
  {
    id: 4,
    text: "Established in 2012, Fin Medical Loans is one of Southern Africa’s largest, independent Medical Finance and Financial Service Providers. Fin Medical Loans is all about enabling everyday South African’s to proceed with their medical procedures if they are unable to immediately cover the costs.",
    title: "MediFin",
    facebookUrl: "https://www.facebook.com/finafricaza",
    linkedInUrl: "https://www.linkedin.com/company/hqfinafrica/",
    image: MediFinImg,
    website: "https://medicalloans.fin.africa",
  },
  // {
  //   id: 7,
  //   text: "TrustGro is a lender with a unique approach to credit, focusing on financial education and SMEs in Kenya and Tanzania. With more than 9000 clients contributing to its books, TrustGro aims to create an inclusive ecosystem where people and organisations can access financial solutions that help them meet their desired objectives.",
  //   title: "TrustGro",
  //   facebookUrl: "https://www.facebook.com/trustgro/",
  //   linkedInUrl: "https://www.linkedin.com/company/trustgro-ke/",
  //   image: TrustGroImg,
  //   website: "https://www.trustgro.com/",
  // },
  {
    id: 5,
    text: "Fin Kenya is a lender with a unique approach to credit, focusing on financial education, asset financing, payroll loans, and SMEs in Kenya. With thousands of happy clients contributing to its books, Fin Kenya aims to improve people's lives through simple, convenient, and accessible financial solutions.",
    title: "Fin Kenya",
    linkedInUrl: "https://www.linkedin.com/company/hqfinafrica/",
    image: FikiaFinanceImg,
    website: "https://ke.fin.africa/",
  },
  {
    id: 6,
    text: "Fin Tanzania is a lending-only financial institution incorporated in Tanzania in 2020. The company is a subsidiary of Fin Group and has sister operations in Kenya, South Africa, Eswatini, and Namibia. With a proven track record in asset financing and other credit solutions, Fin Tanzania is fast becoming a leading credit provider in the region.",
    title: "Fin Tanzania",
    facebookUrl: "https://www.facebook.com/finafricatz/",
    linkedInUrl: "https://www.linkedin.com/company/hqfinafrica/",
    image: FinTanzaniaImg,
    website: "https://tz.fin.africa/",
  },
  {
    id: 8,
    text: "Welcome to Fin Pay, South Africa’s niche Instant Credit Provider. We enable South Africans to obtain the products and services they need to enrich their lives, even if they can’t immediately cover the costs.",
    title: "Fin Pay",
    facebookUrl: "https://www.facebook.com/finafricaza",
    linkedInUrl: "https://www.linkedin.com/company/hqfinafrica/",
    image: eFin,
    website: "https://pay.fin.africa",
  },
  {
    id: 9,
    text: "Fin Home Loans provides responsible, affordable, and transparent housing credit solutions that make a difference in people’s lives as they look to build or buy their dream home.",
    title: "Fin Home Loans",
    facebookUrl: "https://www.facebook.com/finafricaza",
    linkedInUrl: "https://www.linkedin.com/company/hqfinafrica/",
    image: HomeLoansImg,
    website: "https://homeloans.fin.africa",
  },
]

export default allOurCoreProducts
