import React from "react"

const FooterAddress = () => {
  return (
    <div style={footerAddressContainer}>
      <p style={{ margin: 0 }}>
        9th Floor, NeXSky Building, Cybercity 72201, Ebene. Republic
        of Mauritius | © Fin Group Limited {new Date().getFullYear()}. All
        Rights Reserved.
      </p>
    </div>
  )
}

const footerAddressContainer = {
  backgroundColor: `var(--mainWhite)`,
  color: `var(--mainBlackRussianTwo)`,
  padding: "26px",
  textAlign: "center",
  fontSize: "14px",
  lineHeight: "24px",
}

export default FooterAddress
