import * as React from "react"

const Title = ({ header, color }) => (
  <h2
    style={{
      padding: "1em 1em 0em",
      textAlign: "center",
      color: `var(--main${color})`,
      fontWeight: "bold",
    }}
  >
    {header}
  </h2>
)

export default Title
