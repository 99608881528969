import BondsterLogo from "../assets/images/our-key-partners/bondster.svg"
import InnovativeAssessmentsLogo from "../assets/images/our-key-partners/innovative-assessments.svg"
import LendableLogo from "../assets/images/our-key-partners/lendable.svg"
import MintosLogo from "../assets/images/our-key-partners/mintos.svg"
import TransunionLogo from "../assets/images/our-key-partners/transunion.svg"

export const keyPartners = [
  {
    id: 1,
    image: BondsterLogo,
  },
  {
    id: 2,
    image: InnovativeAssessmentsLogo,
  },
  {
    id: 3,
    image: LendableLogo,
  },
  {
    id: 4,
    image: MintosLogo,
  },
  {
    id: 3,
    image: TransunionLogo,
  },
]
