export const TERMS = {
  PRIVACY_POLICY: {
    title: "Privacy Policy",
    message: `
                    <p>
                        1.  <b>INTRODUCTION</b></p>
                    <p>
                        1.1. In this Policy, "we", "us" and "our" refer to Fin Group Limited +, including our subsidiaries, our ultimate holding company and all its direct and indirect subsidiaries (the <b>“Group”</b>). For more information about us, see "How to Contact Us"
                    </p>
                    <p>
                        1.2. We are committed to protecting your privacy.
                    </p>
                    <p style="display: inline-block;position: absolute;">
                        1.3.
                    </p>
                    <p style="display: inline-block; margin-left:30px;">
                        This Policy applies where we are acting as a data controller with respect to the personal data of our website visitors and service users. A data controller determines the purposes and means of the processing of the personal data it receives.
                    </p>
                    <p>
                        2. <b>APPLICATION OF THIS PRIVACY POLICY</b>
                    </p>
                    <p>
                        2.1. This Policy applies to all our websites and platform.
                    </p>
                    <p>
                        2.2. By submitting your details and/or using the website you accept the terms and conditions of the Policy and explicitly consent to the collection, use and disclosure of your personal information in the manner described below.
                    </p>
                    <p>
                        2.3. Our websites may provide links to third-party websites for your convenience and information.
                    </p>
                    <p>
                        2.4. If you access those links, you will leave our websites. We do not control those sites or their privacy practices, which may differ from our own privacy practices.
                    </p>
                    <p>
                        2.5. This Policy does not cover any personal data that you choose to give to unrelated third parties.
                    </p>
                    <p>
                        2.6. We do not monitor or control the information collected by such sites or the privacy practices of any third parties, and we are not responsible for their practices or the content of their sites.
                    </p>
                    <p>
                        3. <b>CUSTOMER PRIVACY NOTICE</b>
                    </p>
                    <p>
                        3.1. This Policy explains how we obtain, use, and disclose your personal information, as is required by the Protection of Personal Information Act 4 of 2013, (“POPIA”).
                    </p>
                    <p>
                        3.2. At Fin Group Limited + We are committed to protecting your privacy and to ensure that your personal information is collected and used properly, lawfully, and transparently.
                    </p>
                    <p>
                        3.3. This Policy sets out:
                    <p>
                    <p style="margin-left: 30px;">
                        3.3.1. who we are;
                    </p>
                    <p style="margin-left: 30px;">
                        3.3.2. what information we collect;
                    </p>
                    <p style="margin-left: 30px;">
                        3.3.3. our aim to provide ongoing financial services;
                    </p>
                    <p style="margin-left: 30px;">
                        3.3.4. to whom we disclose your information;
                    </p>
                    <p style="margin-left: 30px;">
                        3.3.5. how we safeguard your information;
                    </p>
                    <p style="margin-left: 30px;">
                        3.3.6. your rights to access and correction of information;
                    </p>
                    <p style="margin-left: 30px;">
                        3.3.7. changes to this Policy;
                    </p>
                    <p style="margin-left: 30px;">
                        3.3.8. how to contact us; and
                    </p>
                    <p style="margin-left: 30px;">
                        3.3.9. the Information Regulator’s contact details.
                    </p>
                    <p>
                        <b>4. WHO WE ARE</b>
                    </p>
                    <p>
                        4.1. For more information about us, see "How to Contact Us".
                    </p>
                    <p>
                        <b>5. THE INFORMATION WE COLLECT</b>
                    </p>
                    <p>
                        5.1. We collect and process your personal information to provide you with access to our services and products, to help us improve our offerings to you and for certain other purposes explained below.
                    </p>
                    <p>
                        5.2. The type of information we collect will depend on the purpose for which it is collected and used. We will only collect information that we need for that purpose.
                    </p>
                    <p>
                        5.3. We may process the following:
                    </p>
                    <p style="margin-left: 30px;">
                        5.3.1. general personal information such as your name, identity number, gender, date of birth, residential address (and address history) and contact details such as your email address and telephone number (and any other telephone numbers you call from);
                    </p>
                    <p style="margin-left: 30px;">
                        5.3.2. employment related personal information such as your employment history, education, qualifications, experience, demographic information, geographic location, and salary information;
                    </p>
                    <p style="margin-left: 30px;">
                        5.3.3. application information: information you provide to us on applications, including assets, income, and debt;
                    </p>
                    <p style="margin-left: 30px;">
                        5.3.4. account information: information such as your bank account, loan or other;
                    </p>
                    <p style="margin-left: 30px;">
                        5.3.5. transaction information: information about transactions and account activity such as account balances, payment history, account usage including information about our communications with you, enquiries, and responses;
                    </p>
                    <p style="margin-left: 30px;">
                        5.3.6. consumer report information: information from a consumer report, including a credit score and credit history; Information from outside sources: information from outside sources other than consumer report information, loan balances, credit card balances, credit insurance coverage, data from public records, and other verifications by authorised persons or any information you elect to provide;
                    </p>
                    <p style="margin-left: 30px;">
                        5.3.7. information obtained through our use of cookies;
                    </p>
                    <p style="margin-left: 30px;">
                        5.3.8. telephone recordings (if any);
                    </p>
                    <p style="margin-left: 30px;">
                        5.3.9. your marketing preferences; and
                    </p>
                    <p style="margin-left: 30px;">
                        5.3.10. identifiers assigned to your device such as your Internet Protocol (IP) address.
                    </p>
                    <p>
                        5.4. The source of this data is:
                    </p>
                    <p style="margin-left: 30px;">
                        5.4.1. from you (or someone duly authorised on your behalf) when:
                    </p>
                    <p style="margin-left: 72px;">
                        5.4.1.1. viewing our website (via cookies);
                    </p>
                    <p style="margin-left: 72px;">
                        5.4.1.2. using our website to open an account or apply for a product and/or service;
                    </p>
                    <p style="margin-left: 72px;">
                        5.4.1.3. contacting us by email, social media, or telephone (calls are recorded);
                    </p>
                    <p style="margin-left: 72px;">
                        5.4.1.4. completing customer surveys;
                    </p>
                    <p style="margin-left: 30px;">
                        5.4.2. from third parties such as:
                    </p>
                    <p style="margin-left: 72px;">
                        5.4.2.1. service providers who:
                    </p>
                    <p style="margin-left: 128px;">
                        5.4.2.1.1. help us monitor the ease of use of our website and online account opening process, so we can improve the user experience; and
                    </p>
                    <p style="margin-left: 128px;">
                        5.4.2.1.2. help us carry out market research, surveys and business and statistical analysis.
                    </p>
                    <p>
                        <b>
                            6. HOW WE USE YOUR INFORMATION
                        </b>
                    </p>
                    <p>
                        6.1. We will use your personal information only for the purposes for which it was collected or agreed with you, for example:
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.1. to provide our products or services to you, to carry out the transaction you requested and to maintain our relationship;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.2. to facilitate transactions;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.3. to service, maintain or collect accounts;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.4. for product applications and evaluate your eligibility for such products;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.5. to conduct credit reference searches or verifications;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.6. to conduct credit scoring and assessment, ongoing credit management;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.7. to perform risk management including credit risk analysis;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.8. to provide security for our clients and employees;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.9. to conduct account verification;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.10. to confirm and verify your identity or to verify that you are an authorised user for security purposes;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.11. for operational purposes;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.12. for purposes of claim checks;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.13. for the detection and prevention of fraud, crime, money laundering or other malpractice;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.14. for debt tracing or debt recovery;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.15. to conduct market or customer satisfaction research or for statistical analysis;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.16. for audit and record keeping purposes;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.17. in connection with legal proceedings;
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.18. to comply with legal and regulatory requirements or industry codes to which we subscribe, or which apply to us, or when it is otherwise allowed by law (for example to protect our interests).
                    </p>
                    <p style="margin-left: 30px;">
                        6.1.19. to provide financial wellness services through debt counselling;
                    </p>
                    <p>
                        <b>
                            7. SHARING INFORMATION WITH OTHERS
                        </b>
                    </p>
                    <p>
                        7.1. We may disclose your personal data to any member of the Group insofar as reasonably necessary for the purposes, and on the legal bases, set out in this Policy.
                    </p>
                    <p>
                        7.2. We may disclose your personal data to our Group entities and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice, or the establishment, exercise, or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.
                    </p>
                    <p>
                        7.3. We may disclose your information to the following third parties:
                    </p>
                    <p style="margin-left: 30px;">
                        7.3.1. any firm, organisation or person we use to help us operate our business or to provide a service on our behalf, including providers of our payment systems, debt collection agencies we use to collect payments and recover debts, tracing agents or investigators, and suppliers who monitor use of social media to help us improve our decisions;
                    </p>
                    <p style="margin-left: 30px;">
                        7.3.2. any firm, organisation or person who provides us with products or services or who we provide products and services to, including IT suppliers, auditors, marketing agencies, document management providers, tax advisers, post management and print providers, and suppliers who monitor the use of our website and online account opening and application process to help us improve the user experience;
                    </p>
                    <p style="margin-left: 30px;">
                        7.3.3. law enforcement agencies to investigate and prevent crime;
                    </p>
                    <p style="margin-left: 30px;">
                        7.3.4. law firms or any other organisations we instruct to provide legal advice or assist with legal proceedings;
                    </p>
                    <p style="margin-left: 30px;">
                        7.3.5. survey providers and third parties we appoint to carry out market research or business analysis;
                    </p>
                    <p style="margin-left: 30px;">
                        7.3.6. our regulators and governmental authorities, ombudsmen, or other authorities, including tax authorities;
                    </p>
                    <p>
                        7.4. We may also disclose your personal data to other third parties where:
                    </p>
                    <p style="margin-left: 30px;">
                        7.4.1. you have provided your consent to share your information with the third party;
                    </p>
                    <p style="margin-left: 30px;">
                        7.4.2. the disclosure is required by law or by a regulator with authority over us or you, for example where there is a statutory obligation or court order;
                    </p>
                    <p style="margin-left: 30px;">
                        7.4.3. payment services providers only to the extent necessary for the purposes of processing your payments, refunding such payments, and dealing with complaints and queries relating to such payments and refunds; or
                    </p>
                    <p style="margin-left: 30px;">
                        at your option, and only with your consent, we may also share your information with partner organisations we have chosen carefully, so they can contact you about their products and services.
                    </p>
                    <p>
                        <b>
                            8. THIRD PARTY LINKS
                        </b>
                    </p>
                    <p>
                        8.1. The website may contain links to third party websites, or you may be directed to our sites through a third-party website. If you follow a link to any of these websites, please note that these websites have their own terms and privacy policies and that we do not accept any responsibility or liability for them.
                    </p>
                    <p>
                        8.2. We are not responsible for any representations, information, warranties or content on any website of any third party, we do not exercise control over third parties' privacy policies and you should refer to the policy of any such third party to see how they protect your privacy.
                    </p>
                    <p>
                        <b>
                            9. COOKIES
                        </b>
                    </p>
                    <p>
                        9.1. We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.
                    </p>
                    <p>
                        9.2. A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.
                    </p>
                    <p>
                        9.3. Cookies may be either "persistent" cookies or "session" cookies. A persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date. A session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.
                    </p>
                    <p>
                        9.4. We may use cookies for the following purposes:
                    </p>
                    <p style="margin-left: 30px;">
                        9.4.1. authentication - we may use cookies to identify you when you visit our website and as you navigate our website;
                    </p>
                    <p style="margin-left: 30px;">
                        9.4.2. status - we may use cookies to help us to determine if you are logged into our website;
                    </p>
                    <p style="margin-left: 30px;">
                        9.4.3. personalisation - we may use cookies to store information about your preferences and to personalise the website for you;
                    </p>
                    <p style="margin-left: 30px;">
                        9.4.4. security - we may use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our website and services generally;
                    </p>
                    <p style="margin-left: 30px;">
                        9.4.5. advertising - we may use cookies to help us to display advertisements that will be relevant to you;
                    </p>
                    <p style="margin-left: 30px;">
                        9.4.6. analysis - we use may cookies to help us to analyse the use and performance of our website and services; and
                    </p>
                    <p style="margin-left: 30px;">
                        9.4.7. cookie consent - we may use cookies to store your preferences in relation to the use of cookies more generally.
                    </p>
                    <p>
                        9.5. Our service providers use cookies, and those cookies may be stored on your computer when you visit our website.
                    </p>
                    <p>
                        9.6. Managing your cookies and cookie preferences must be done from within your browser’s options/preferences. If you choose not to accept cookies from our Website this may limit its functionalities or performance.
                    </p>
                    <p>
                        <b>
                            10. ONGOING FINANCIAL SERVICES
                        </b>
                    </p>
                    <p>
                        10.1. Given our aim to provide you with ongoing financial services, we would like to use your information to keep you informed about other financial products and services which may be of particular interest to you.
                    </p>
                    <p>
                        10.2. You may give and withdraw consent and tell us what your communication preferences are by contacting us at the numbers/addresses provided below.
                    </p>
                    <p>
                        10.3. However, please note, if you opt out of receiving marketing emails, we will continue to send you service related (non-marketing) communications, such as payment reminders.
                    </p>
                    <p>
                        11. INFORMATION SECURITY
                    </p>
                    <p>
                        11.1. We are legally obliged to provide adequate protection for the personal information we hold and to stop unauthorised access and use of personal information. We will, on an ongoing basis, continue to review our security controls and related processes to ensure that your personal information is secure.
                    </p>
                    <p>
                        11.2. We are committed to implementing leading data security safeguards.
                    </p>
                    <p>
                        11.3. We have specialised security teams who constantly review and improve our measures to protect your personal information from unauthorised access, accidental loss, disclosure, or destruction.
                    </p>
                    <p>
                        11.4. If we have a contract with another organisation to provide us with services or a service on our behalf to process your personal information, we will ensure that your information is protected and that they only process your information in the way we have authorised them to. When we contract with third parties, we impose appropriate security, privacy, and confidentiality obligations on them to ensure that personal information that we remain responsible for, is kept secure.
                    </p>
                    <p>
                        <b>
                            12. INTERNATIONAL TRANSFER OF DATA
                        </b>
                    </p>
                    <p>
                        12.1. There will be times when we will process your personal information in other countries for ordinary business purposes or to carry out your instructions. We will only process your personal information with your consent.
                    </p>
                    <p>
                        12.2. If our servers or suppliers and service providers are based outside South Africa, or if our services are hosted in systems or servers outside South Africa and/or if you use our services and products while visiting countries outside South Africa, we may also need to transfer your information to other entities in the Group or third-party service providers in countries outside South Africa, in which case we will ask the party to whom we transfer your personal information to agree to our privacy principles and practices. This will be included in the relevant contractual agreements.
                    </p>
                    <p>
                        12.3. We will make sure that your information is protected and enter into appropriate agreements to achieve this.
                    </p>
                    <p>
                        <b> 13. YOUR RIGHTS: </b>
                    </p>
                    <p>
                        13.1. <span style="font-weight: bold;">The right to access personal information</span>
                    </p>
                    <p style="margin-left: 40px;">
                        You have the right to establish whether we hold personal information related to you, including the right to request access to that personal information. We will take all reasonable steps to confirm your identity before providing details of your personal information.
                    </p>
                    <p>
                        13.2. The right to have personal information corrected or deleted
                    </p>
                    <p style="margin-left: 40px;">
                        You have the right to request, where necessary, that your personal information must be corrected or deleted where we are no longer authorised to retain the personal information. You may do this by contacting us at the numbers/addresses provided below. We may, subject to any statutory and contractual record keeping requirements, approve the destruction of the personal information.
                    </p>
                    <p>
                        13.3. The right to object to the processing of personal information
                    </p>
                    <p style="margin-left: 40px;">
                        You have the right, on reasonable grounds, to object to the processing of your personal information. In such circumstances, we will give due consideration to the request and the requirements of POPIA. We may cease to use or disclose your personal information and may, subject to any statutory and contractual record keeping requirements, also approve the destruction of the personal information.
                    </p>
                    <p>
                        13.4. The right to object to direct marketing
                    </p>
                    <p style="margin-left: 40px;">
                        You have the right to object to the processing of your personal information for purposes of direct marketing by means of unsolicited electronic communications.
                    </p>
                    <p>
                        13.5. The right to complain
                    </p>
                    <p style="margin-left: 40px;">
                        You have the right to submit a complaint to the Information Regulator regarding an alleged infringement of any of the rights protected under POPIA and to institute civil proceedings regarding the alleged non-compliance with the protection of your personal information.
                    </p>
                    <p>
                        13.6. The right to query an automated decision
                    </p>
                    <p style="margin-left: 40px;">
                        You have the right to query a decision that was made about a product or service that you have applied for and that was made solely by automated means.
                    </p>
                    <p>
                       <b> 14. RETENTION OF INFORMATION</b>
                    </p>
                    <p style="margin-left: 40px;">
                        We are obliged to keep your information if we require it for our lawful business purposes or if required by the law. If the law requires us to keep the personal information, it will not be deleted upon your request. The deletion of certain personal information may lead to the termination of your business relationship with us.
                    <p>
                    <p>
                        <b>15. CHANGES TO THIS POLICY</b>
                    </p>
                    <p style="margin-left: 40px;">
                        Please note that we may amend this Policy from time to time. Please check this website periodically to inform yourself of any changes.
                    </p>
                    <p>
                        <b>16. HOW TO CONTACT US</b>
                    </p>
                    <p>
                        16.1. If you have questions about this Policy or believe we have not adhered to it, or need further information about our privacy practices or wish to give or withdraw consent, exercise preferences or access or correct your personal information, please contact us at the following numbers/addresses:
                    </p>
                    <p style="margin-left: 38px;">
                        16.1.1. Fin Group Limited +, a private company duly incorporated under laws of Mauritius with registration number 180294.
                    </p>
                    <p style="margin-left: 38px;">
                        16.1.2. Our principal place of business is 9th Floor, NeXSky Building Ebѐne Cybercity 72201, Republic of Mauritius.
                    </p>
                    <p style="margin-left: 38px;">
                        16.1.3. You can contact us on any of the details on our site.
                    </p>
                    <p style="margin-left: 72px;">
                        16.1.3.1 E-mail: info@fin.africa
                    </p>
                `,
  },
}
