import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import FooterAddress from "./FooterAddress"
import { BottomBorderTextField, Button } from "../../ui"
import { useSubscribeToEverlytic } from "../../../hooks"
import { responseMsg } from "../../../constants"
import FooterLogo from "../../../assets/images/footer/footer-logo.svg"
import FacebookLogo from "../../../assets/images/footer/facebook.svg"
import FacebookLogoHover from "../../../assets/images/footer/facebook-hover.svg"
import TwitterLogo from "../../../assets/images/footer/twitter.svg"
import TwitterLogoHover from "../../../assets/images/footer/twitter-hover.svg"
import LinkedInLogo from "../../../assets/images/footer/linked-in.svg"
import LinkedInLogoHover from "../../../assets/images/footer/linked-in-hover.svg"
import "./index.scss"

const Footer = () => {
  const [email, setEmail] = useState("")
  const [isClicked, setIsClicked] = useState(false)

  const component = "footer"

  const [userSubscribed, subscribeComponent, userSubscribedError, submitForm] =
    useSubscribeToEverlytic(email, component, isClicked)

  const signUpUser = e => {
    e.preventDefault()
    setIsClicked(true)
  }

  const resetFormAfterFiveSeconds = () => {
    setTimeout(() => {
      setEmail("")
    }, 5000)
  }

  useEffect(() => {
    if (isClicked) {
      submitForm()
    }

    if (userSubscribed) resetFormAfterFiveSeconds()

    setIsClicked(false)
  }, [isClicked, userSubscribed])

  const emailAddress = process.env.GATSBY_EMAIL_ADDRESS
  const facebookUrl = process.env.GATSBY_FACEBOOK_URL
  const linkedInUrl = process.env.GATSBY_LINKEDIN_URL
  const twitterUrl = process.env.GATSBY_TWITTER_URL

  return (
    <div>
      <div className="footer__container">
        <Container fluid className="footer__section">
          <Row>
            <Col lg={1}>
              <Link to="/">
                <img src={FooterLogo} alt="logo" />
              </Link>
            </Col>
            <br />
            <Col lg={2}>
              <h6>Fin</h6>
              <Link to="/about-us" className="footer__links">
                About us
              </Link>
              <br />
              <Link to="/products" className="footer__links">
                Products
              </Link>
              <br />
              <Link to="/ventures" className="footer__links">
                Ventures
              </Link>
              {/* <br />
              <Link to="/" className="footer__links">
                Countries
              </Link> */}
              <br />
              <Link to="/news" className="footer__links">
                News
              </Link>
              <br />
              <Link to="/contact" className="footer__links">
                Contact
              </Link>
            </Col>
            <Col lg={2}>
              <h6>Legal</h6>
              <Link to="/privacy-policy" className="footer__links">
                Privacy Policy
              </Link>
              <br />
              {/* <Link to="/terms" className="footer__links">
                Terms & Conditions
              </Link> */}
            </Col>
            <Col lg={3}>
              <h6>Contact</h6>
              <p>
                For more information, or to get in touch with Fin, please send
                an email to{" "}
                <a
                  href={`mailto:${emailAddress}`}
                  title={emailAddress}
                  className="footer__links"
                  target="_blank"
                  rel="noreferrer"
                >
                  {emailAddress}
                </a>
              </p>
              {/* <div className="footer__socialLinks">
                <a href={linkedInUrl} title={linkedInUrl} target="_blank">
                  <img
                    src={LinkedInLogo}
                    alt="linkedIn logo"
                    onMouseOver={e => (e.currentTarget.src = LinkedInLogoHover)}
                    onMouseOut={e => (e.currentTarget.src = LinkedInLogo)}
                  />
                </a>
                <a href={twitterUrl} title={twitterUrl} target="_blank">
                  <img
                    src={TwitterLogo}
                    alt="twitter logo"
                    onMouseOver={e => (e.currentTarget.src = TwitterLogoHover)}
                    onMouseOut={e => (e.currentTarget.src = TwitterLogo)}
                  />
                </a>
                <a href={facebookUrl} title={facebookUrl} target="_blank">
                  <img
                    src={FacebookLogo}
                    alt="facebook logo"
                    onMouseOver={e => (e.currentTarget.src = FacebookLogoHover)}
                    onMouseOut={e => (e.currentTarget.src = FacebookLogo)}
                  />
                </a>
              </div> */}
            </Col>
            <Col lg={3} className="footer__address">
              <h6>Address</h6>
              <p>
                9th Floor, NeXSky Building <br />
                Ebѐne Cybercity 72201, <br />
                Republic of Mauritius
              </p>
            </Col>
            {/* <Col lg={4}>
              <h6>Sign up to our newsletter</h6>
              <form className="footer__newsLetter" onSubmit={signUpUser}>
                <BottomBorderTextField
                  type="email"
                  placeholder="Email"
                  value={email || ""}
                  onChange={e => setEmail(e.target.value)}
                />
                <Button
                  title="Sign up"
                  type="submit"
                  customClass="footer__newsBtn"
                  color="white"
                />
              </form>
              {userSubscribed && subscribeComponent === "footer" ? (
                <p className="footer__subscribe">
                  {responseMsg.SUBSCRIBE_SUCCESS}
                </p>
              ) : null}
              {!userSubscribed &&
                userSubscribedError !== "" &&
                subscribeComponent === "footer" ? (
                <p className="footer__subscribeError">
                  {responseMsg.SUBSCRIBE_ERROR}
                </p>
              ) : null}
            </Col> */}
          </Row>
        </Container>
      </div>
      <FooterAddress />
    </div>
  )
}

export default Footer
