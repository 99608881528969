import Ashvin from "../assets/images/meet-the-team/board/ashvin.jpg"
import Leonard from "../assets/images/meet-the-team/board/leonard.jpg"
import Lutz from "../assets/images/meet-the-team/board/lutz.png"
import Manuel from "../assets/images/meet-the-team/board/manuel.png"
import Matsi from "../assets/images/meet-the-team/board/matsi.png"
import Preetam from "../assets/images/meet-the-team/board/preetam.png"
import TimothyNuy from "../assets/images/meet-the-team/board/tim-nuy.png"
import TonderaiMutesva from "../assets/images/meet-the-team/board/tonderai-mutesva.png"
import BabylonMukunga from "../assets/images/meet-the-team/team/babylong-mukunga.png"
import ChristopherRwechungura from "../assets/images/meet-the-team/team/christopher-rwechungura.png"
import Kiema from "../assets/images/meet-the-team/team/kiema.png"
import Nadya from "../assets/images/meet-the-team/team/nadya.jpg"
import Serah from "../assets/images/meet-the-team/team/serah.png"
import Simba from "../assets/images/meet-the-team/team/simba.png"
import Raymond from "../assets/images/meet-the-team/team/raymond-c.png"

export const team = [
  {
    id: 1,
    name: "Timothy Nuy",
    title: "Co-CEO",
    linkedInLink: "https://www.linkedin.com/in/timothynuy/",
    image: TimothyNuy,
  },
  {
    id: 2,
    name: "Tonderai Mutesva",
    title: "Co-CEO",
    linkedInLink: "https://www.linkedin.com/in/tonderai-mutesva/",
    image: TonderaiMutesva,
  },
  {
    id: 9,
    name: "Serah Gikonyo",
    title: "CFO",
    linkedInLink: "https://www.linkedin.com/in/serah-gikonyo-067306143/",
    image: Serah,
  },
  {
    id: 3,
    name: "Simbarashe Machanzi",
    title: "CTO",
    linkedInLink: "https://www.linkedin.com/in/jsmachanzi/",
    image: Simba,
  },
  // {
  //   id: 5,
  //   name: "Matthew Schulz",
  //   title: "CMO",
  //   linkedInLink: "https://www.linkedin.com/in/matthewaschulz/",
  //   image: MatthewSchulz,
  // },
  {
    id: 8,
    name: "Babylon Mukunga",
    title: "South Africa MD",
    linkedInLink: "https://www.linkedin.com/in/babylon-mukunga-1980aa2b/",
    image: BabylonMukunga,
  },
  // {
  //   id: 6,
  //   name: "Gerrie Fourie",
  //   title: "Southern Africa MD",
  //   linkedInLink: "https://www.linkedin.com/in/gerrie-fourie-93984190/",
  //   image: GerrieFourie,
  // },
  {
    id: 7,
    name: "Christopher Rwechungura",
    title: "Tanzania MD",
    linkedInLink: "https://www.linkedin.com/in/chrisrwechu/",
    image: ChristopherRwechungura,
  },
  // {
  //   id: 4,
  //   name: "Raymond Chepkwony",
  //   title: "Kenya MD",
  //   linkedInLink: "https://www.linkedin.com/in/raymondchepkwony/",
  //   image: Raymond,
  // },
  // {
  //   id: 10,
  //   name: "Nadya Issa",
  //   title: "Head of People",
  //   linkedInLink: "https://www.linkedin.com/in/nadya-issa/",
  //   image: Nadya,
  // },
  // {
  //   id: 8,
  //   name: "Dennis Kiema",
  //   title: "Chief of Staff",
  //   linkedInLink: "https://www.linkedin.com/in/denniskiema/",
  //   image: Kiema,
  // },
  // {
  //   id: 9,
  //   name: "Benedikt Kramer",
  //   title: "Chief Business Development Officer",
  //   linkedInLink: "https://www.linkedin.com/in/benediktkramer/",
  //   image: BenediktKramer,
  // },
  // {
  //   id: 11,
  //   name: "Marika Calitz",
  //   title: "Head of Internal Audit",
  //   linkedInLink: "https://www.linkedin.com/in/marika-calitz-95302320/ ",
  //   image: MarikaCalitz,
  // },
  // {
  //   id: 12,
  //   name: "Geoff Ferrier",
  //   title: "Head of Insurance",
  //   linkedInLink: "https://www.linkedin.com/in/geoffrey-ferrier-72005783/",
  //   image: GeoffFerrier,
  // },
  // {
  //   id: 12,
  //   name: "Luke Kane-Berman",
  //   title: "Head of Growth",
  //   linkedInLink: "https://www.linkedin.com/in/luke-kane-berman-310947127/",
  //   image: LukeKaneBerman,
  // },
]

export const board = [
  {
    id: 1,
    name: "Matsi Modise",
    title: "Chairwoman",
    linkedInLink: "https://www.linkedin.com/in/matsi-modise-94a16230/",
    image: Matsi,
  },
  {
    id: 2,
    name: "Timothy Nuy",
    title: "Co-Founder / Co-Chief Executive Officer",
    linkedInLink: "https://www.linkedin.com/in/timothynuy/",
    image: TimothyNuy,
  },
  {
    id: 3,
    name: "Tonderai Mutesva",
    title: "Co-Founder / Co-Chief Executive Officer",
    linkedInLink: "https://www.linkedin.com/in/tonderai-mutesva/",
    image: TonderaiMutesva,
  },
  // {
  //   id: 4,
  //   name: "Tamuka Mpofu",
  //   title: "Chief Financial Officer",
  //   linkedInLink: "https://www.linkedin.com/in/tamuka-mpofu-8858642b/",
  //   image: TamukaMpofu,
  // },
  {
    id: 5,
    name: "Lutz Seebacher",
    title: "Executive Director",
    linkedInLink: "https://www.linkedin.com/in/lutz-seebacher-8228426b/",
    image: Lutz,
  },
  {
    id: 6,
    name: "Manuel Koser",
    title: "Non-Executive Director",
    linkedInLink: "https://www.linkedin.com/in/manuel-koser-baa376/",
    image: Manuel,
  },
  {
    id: 7,
    name: "Leonard Stiegeler",
    title: "Non-Executive Director",
    linkedInLink: "https://www.linkedin.com/in/leonardstiegeler/",
    image: Leonard,
  },
  {
    id: 8,
    name: "Preetam Prayag",
    title: "Non-Executive Director",
    linkedInLink: "https://www.linkedin.com/in/preetam-prayag-34683410/",
    image: Preetam,
  },
  {
    id: 9,
    name: "Ashvin Chundoo",
    title: "Non-Executive Director",
    linkedInLink: "https://www.linkedin.com/in/ashvin-chundoo-634318188/",
    image: Ashvin,
  },
]
