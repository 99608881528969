import Countries from "../assets/images/home/countries-icon.svg"
import Customers from "../assets/images/home/customers-icon.svg"
import Loans from "../assets/images/home/loans-disbursed-icon.svg"

export const growth = [
  {
    id: 1,
    text: "Countries",
    number: 3,
    image: Countries,
    prefix: "",
    suffix: "",
  },
  {
    id: 2,
    text: "Customers",
    number: 240,
    image: Customers,
    prefix: "",
    suffix: "k",
  },
  {
    id: 3,
    text: "Loans disbursed",
    number: 310,
    image: Loans,
    prefix: "$",
    suffix: "m",
  },
]
