import React from "react"
import "./TextField.scss"

const TextField = ({ type, placeholder, onChange, value, customClass }) => {
  return (
    <input
      type={type || "text"}
      placeholder={placeholder}
      onChange={onChange}
      className={`${customClass} ${"textField__style"}`}
      value={value}
      required
    />
  )
}

// const textFieldStyle = {
//   border: "3px solid var(--mainQuartz)",
//   backgroundColor: `var(--mainWhite)`,
//   borderRadius: ".5em",
//   width: "50%",
//   color: `var(--mainBlackRussian)`,
//   padding: "0em 1em",
//   outline: "none",
//   fontWeight: 300,
//   fontSize: `13px`,
//   fontFamily: `'Open Sans', sans-serif`
// }

export default TextField
