import FractalLabsImg from "../assets/images/our-portfolio/fractal.svg"
import HappyPayImg from "../assets/images/our-portfolio/happy-pay-logo.svg"
import HelloHRImg from "../assets/images/our-portfolio/hello-hr.svg"
import DebtHelperImg from "../assets/images/our-portfolio/debt-helper.svg"
import AwamoImg from "../assets/images/our-portfolio/awamo.svg"
import GetBucksImg from "../assets/images/our-portfolio/ESW_Logo.svg"
import MTekImg from "../assets/images/our-portfolio/MTek.svg"
import FinConnectLogo from "../assets/images/our-portfolio/Fin Logo_Fin_Connect_Dark.svg"

const allOurPortfolio = [
  {
    id: 1,
    text: "Happy Pay is Africa’s leading Buy Now Pay Later platform financially empowering consumers with a zero-interest financial solution and simple payments. Offering technology that makes BNPL appealing and safe, Happy Pay grows merchants’ customer bases through influencer social distribution and by providing quick approval for interest-free shopping. Happy Pay also offsets its transaction impact and encourages conscious consumerism through its reforesting initiative called Happy World.",
    title: "HappyPay",
    facebookUrl: "#",
    linkedInUrl: "#",
    image: HappyPayImg,
    website: "https://happypay.co.za/",
  },
  {
    id: 2,
    text: "Debt Helper offers personalised Debt Management services. Their team of experts find the best solution for their clients to get ahead of their debt by assessing their financial situation, renegotiating credit agreements and investigating reckless lending. Debt Helper focus on technology to drive their processes and their systems integrate with South Africa’s major credit providers to ensure fast and efficient solutions. With a wide range of Debt Management solutions available, Debt Helper help people get back in control of their finances!",
    title: "Debt Helper",
    facebookUrl: "#",
    linkedInUrl: "#",
    image: DebtHelperImg,
    website: "https://debthelper.co.za/",
  },
  {
    id: 3,
    text: "This artificial intelligence business uses data to unlock insights for strategic development within organisations. Fractal Labs converts data into measurable and scalable financial and risk tools to enable businesses to run faster and more efficiently while reducing operational costs and overcoming regulatory, financial and fraud challenges.",
    title: "Fractal Labs",
    facebookUrl: "https://www.facebook.com/FractalLabs",
    linkedInUrl: "https://www.linkedin.com/company/fractallabs",
    image: FractalLabsImg,
    website: "https://fractallabs.net/",
  },
  {
    id: 4,
    text: "awamo offers awamo® 360, a mobile banking software tailored for microfinance institutions and SACCOs. awamo® 360 completely digitizes your business with maximum convenience and at minimal cost. You can use any Android® mobile phone or internet-connected PC.",
    title: "Awamo",
    facebookUrl: "https://www.facebook.com/awamo.inc/",
    linkedInUrl: "https://www.linkedin.com/company/awamo-gmbh/",
    image: AwamoImg,
    website: "https://awamo.com/",
  },
  {
    id: 5,
    text: "GetBucks is an Eswatini registered lender which offers short-term and long-term loans to parastatal and private employee",
    title: "GetBucks Eswatini",
    facebookUrl: "https://www.facebook.com/1604799913105114/%20",
    linkedInUrl:
      "https://www.linkedin.com/company/getbucks-eswatini/?originalSubdomain=sz",
    image: GetBucksImg,
    website: "https://getbucks.co.sz/",
  },
  {
    id: 6,
    text: "mTek-Services is a B2C digital online insurance platform that provides an entirely paperless ecosystem.",
    title: "MTek Services",
    facebookUrl: "https://www.facebook.com/mtekservicesKE",
    linkedInUrl: "https://www.linkedin.com/company/mtek-services/",
    image: MTekImg,
    website: "https://mtek-services.com/",
  },
  {
    id: 7,
    text: ' Fin Connect services microfinance banks by enhancing their value proposition to customers by allowing for higher credit or better saving tools. The technology behind this offering is supported by Fin’s earlier acquisition of the microfinance technology services provider Awamo.',
    title: 'Fin Connect',
    image: FinConnectLogo
  }
]

export default allOurPortfolio
